<template>
  <v-card outlined class="mb-3">
    <v-list-item class="box" :href="$href()" @[clickCondition]="$onClick()">
      <!-- <div v-if="spec.imageUrl" class="left-panel">
        <glib-component :spec="{ view: 'avatar', url: spec.imageUrl }" />
      </div>     -->

      <v-list-item-avatar
        v-if="$type.isString(spec.imageUrl)"
        :tile="!spec.avatar"
        :size="100"
        class="left-panel"
      >
        <img :src="spec.imageUrl" />
      </v-list-item-avatar>

      <div style="display: flex; width: 100%;">
        <div style="flex: 1 auto; line-height: 1;">
          <glib-component :spec="{ view: 'h4', text: spec.title }" />

          <div v-if="spec.subtitle">
            <glib-component :spec="{ view: 'spacer', height: 4 }" />
            <glib-component
              :spec="{ view: 'label', text: spec.subtitle, styleClasses: ['small'] }"
            />
          </div>

          <div v-if="spec.subsubtitle">
            <glib-component :spec="{ view: 'spacer', height: 4 }" />
            <glib-component
              :spec="{ view: 'label', text: spec.subsubtitle, styleClasses: ['small'] }"
            />
          </div>

          <div v-if="data.description" class="mt-2">
            <glib-component :spec="{ view: 'p', text: data.description }" />
          </div>

          <div v-if="chips.length > 0" class="mt-1">
            <template v-for="(item, item_index) in chips">
              <v-chip :key="item_index" class="ma-1" :color="item.color">{{
                item.text
              }}</v-chip>
            </template>
          </div>
        </div>
        <div style="flex-shrink: 0;">
          <div class="menu">
            <glib-component :spec="{ view: 'h4', text: data.budget, textAlign: 'right' }" />
            <templates-menu :edit-buttons="spec.editButtons || []" />
          </div>

          <div class="rating">
            <template v-for="(item, index) in spec.rightButtons">
              <common-button
                :key="index"
                :spec="buttonSpec(item)"
                :disabled="$isBusy"
              />
            </template>

            <template v-for="index in spec.data.rating">
              <glib-component :spec="{ view: 'icon', material: { name: 'star' }, color: '#f3a742' }" :key="index" />
            </template>
          </div>

          <div class="share" v-if="spec.data.shareButtons">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click.stop.prevent=""
                >
                  <glib-component :spec="{ view: 'icon', material: { name: 'share' } }" />
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in spec.data.shareButtons"
                  :key="index"
                >
                  <v-list-item-title>
                    <glib-component :spec="Object.assign({ view: 'shareButton' }, item)" />
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>

      </div>

    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: ["spec", "index"],
  data() {
    return {
      data: {},
      chips: [],
      editButtons: []
    };
  },
  computed: {
    clickCondition() {
      if (this.spec.onClick || this.spec.onLongPress) {
        // This will show the clickable indication
        return "click";
      }
      return null;
    }
  },
  methods: {
    $ready() {
      this.data = this.spec.data || {};

      this.chips = (this.spec.chips || []).map(item => {
        var color = null;
        this.$type.ifArray(item.styleClasses, classes => {
          for (const val of ["success", "info", "warning", "error"]) {
            if (classes.includes(val)) {
              color = val;
            }
          }
        });
        return Object.assign({}, item, { color: color });
      });

      this.editButtons = this.spec.editButtons || [];
    },
    buttonSpec(item) {
      return Object.assign({}, item, {
        view: "button-v1",
        styleClasses: ["primary"]
      });
    }
  }
  // computed: {
  //   iconColor: function () {
  //     return '#FFF'
  //   },
  //   iconBackgroundColor: function () {
  //     return '#EEE'
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.box {
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: flex-end;
}
.left-panel {
  margin-right: 12px;
}
.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .v-list-item__action {
    margin: 0;
  }
}
.rating {
  padding-top: 4px;
  display: flex;
  justify-content: flex-end;
}
.share {
  margin-top: 16px;
  > * {
    display: inline-block;
    margin-left: 10px;
  }
}

// .menu {
//   background-color: #ff0000;
//   display: flex;
//   flex-direction: row;
//   h4 {
//     background-color: #00ff00;
//     // display: inline;
//   }
// }
// .menu .v-list-item__action {
//   margin: 4px 0 4px 14px;
// }
</style>

<!--
<template>
  <v-list-tile v-if="spec.template != 'spacer'" :href="genericHref()" @click="genericOnClick" avatar>
    <v-list-tile-avatar v-if="$type.isString(spec.imageUrl)" :tile="!spec.imageCircle">
      <img :src="spec.imageUrl" />123
    </v-list-tile-avatar>
    <v-list-tile-avatar v-else-if="$type.isObject(spec.icon)">
      <views-icon :spec="spec.icon" />
    </v-list-tile-avatar>
    <v-list-tile-content>
      <v-list-tile-title v-html="spec.title" class="heading2" :style="`color: ${appColorTabDark};`"></v-list-tile-title>
      <v-list-tile-sub-title v-if="spec.subtitle" v-html="spec.subtitle" class="paragraph"></v-list-tile-sub-title>
      <v-list-tile-sub-title v-if="spec.subsubtitle" v-html="spec.subsubtitle"></v-list-tile-sub-title>
    </v-list-tile-content>
  </v-list-tile>
</template>

<script>
  export default {
    props: ['spec', 'index'],
    computed: {
      iconColor: function () {
        return '#FFF'
      },
      iconBackgroundColor: function () {
        return '#EEE'
      }
    }
  }
</script>
-->