var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{staticClass:"footer-container",class:{
    xl: _vm.$vuetify.breakpoint.xl,
    lg: _vm.$vuetify.breakpoint.lg,
    md: _vm.$vuetify.breakpoint.md,
    sm: _vm.$vuetify.breakpoint.sm,
    xs: _vm.$vuetify.breakpoint.xs
  },attrs:{"padless":"","height":"auto"}},[_c('div',{staticClass:"bottom-footer py-6"},[_c('v-row',{staticClass:"content mx-auto",class:{
      'steps-container-md-up': _vm.$vuetify.breakpoint.mdAndUp,
      'steps-container-sm-down': _vm.$vuetify.breakpoint.smAndDown
    },staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"steps-text",attrs:{"align-self":"center","cols":"12","sm":"12","md":"6"}},[_c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"logo",attrs:{"src":_vm.spec.logoUrl}})])])],1),_vm._v(" "),_c('v-row',{staticClass:"content mx-auto",class:{
        'steps-container-md-up': _vm.$vuetify.breakpoint.mdAndUp,
        'steps-container-sm-down': _vm.$vuetify.breakpoint.smAndDown
      },staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"steps-text",attrs:{"align-self":"center","cols":"12","sm":"12","md":"4"}},_vm._l((_vm.spec.column1Buttons),function(button,buttonIndex){return _c('a',{key:buttonIndex,staticClass:"footer-link",attrs:{"href":_vm.$href(button)},on:{"click":function($event){return _vm.$onClick($event, button)}}},[_vm._v(_vm._s(button.text))])}),0),_vm._v(" "),_c('v-col',{staticClass:"steps-text",attrs:{"align-self":"center","cols":"12","sm":"12","md":"4"}},_vm._l((_vm.spec.column2Buttons),function(button,buttonIndex){return _c('a',{key:buttonIndex,staticClass:"footer-link",attrs:{"href":_vm.$href(button)},on:{"click":function($event){return _vm.$onClick($event, button)}}},[_vm._v(_vm._s(button.text))])}),0),_vm._v(" "),_c('v-col',{staticClass:"steps-text",attrs:{"align-self":"center","cols":"12","sm":"12","md":"4"}},_vm._l((_vm.spec.column3Buttons),function(button,buttonIndex){return _c('a',{key:buttonIndex,staticClass:"footer-link",attrs:{"href":_vm.$href(button)},on:{"click":function($event){return _vm.$onClick($event, button)}}},[_vm._v(_vm._s(button.text))])}),0)],1)],1),_vm._v(" "),_c('div',{staticClass:"bottom-footer py-6"},[_c('div',{staticClass:"content"},[_c('p',{staticClass:"copyright"},[_vm._v("\n        "+_vm._s(_vm.spec.copyrightText)+"\n      ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }