/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import { Vue, settings } from 'glib-web/index.js'
// require("channels")
// settings.reactive = true

settings.themes = {
  light: {
    // primary: '#fbb163'
    primary: "#ff4c03"
    // secondary: '#ff0000',
  }
};

import mixin   from 'custom/mixins/styling.js'
Vue.mixin(mixin)

import CommonTemplate from 'custom/templates/common'
Vue.component('template-custom-common', CommonTemplate)

import FooterLayoutTemplate from 'custom/templates/layouts/footer'
Vue.component('template-layouts-footer', FooterLayoutTemplate)

// import HomeIndexHeroTemplate from 'custom/templates/home/indexHero'
// Vue.component('template-home-indexHero', HomeIndexHeroTemplate)

// import HomeIndexExpertiseTemplate from 'custom/templates/home/indexExpertise'
// Vue.component('template-home-indexExpertise', HomeIndexExpertiseTemplate)

// import HomeIndexHowItWorksTemplate from 'custom/templates/home/indexHowItWorks'
// Vue.component('template-home-indexHowItWorks', HomeIndexHowItWorksTemplate)

// import HomeIndexWhatItIs from 'custom/templates/home/indexWhatItIs'
// Vue.component('template-home-indexWhatItIs', HomeIndexWhatItIs)

// import HomeIndexGetStartedTemplate from 'custom/templates/home/indexGetStarted'
// Vue.component('template-home-indexGetStarted', HomeIndexGetStartedTemplate)

// import HomeIndexTestimonyTemplate from 'custom/templates/home/indexTestimony'
// Vue.component('template-home-indexTestimony', HomeIndexTestimonyTemplate)

// import HomeIndexJourneyTemplate from 'custom/templates/home/indexJourney'
// Vue.component('template-home-indexJourney', HomeIndexJourneyTemplate)

// import DashboardHeroTemplate from "custom/templates/dashboard/hero";
// Vue.component('template-dashboard-hero', DashboardHeroTemplate);

// import FAQDetailIntroTemplate from "custom/templates/intros/faq_detail";
// Vue.component("template-intros-faq_detail", FAQDetailIntroTemplate);

import NewFooterLayoutTemplate from 'custom/templates/layouts/newFooter'
Vue.component('template-layouts-newFooter', NewFooterLayoutTemplate)

