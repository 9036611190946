<template>
  <v-footer
    class="footer-container"
    padless
    height="auto"
    :class="{
      xl: $vuetify.breakpoint.xl,
      lg: $vuetify.breakpoint.lg,
      md: $vuetify.breakpoint.md,
      sm: $vuetify.breakpoint.sm,
      xs: $vuetify.breakpoint.xs
    }"
  >

    <div class="bottom-footer py-6">
    <v-row
      style="width: 100%;"
      class="content mx-auto"
      :class="{
        'steps-container-md-up': $vuetify.breakpoint.mdAndUp,
        'steps-container-sm-down': $vuetify.breakpoint.smAndDown
      }"
    >
      <v-col align-self="center" cols="12" sm="12" md="6" class="steps-text">
        <a href="/">
          <img class="logo" :src="spec.logoUrl" />
        </a>
        <!-- <div
          v-for="(group, index) in spec.navLinks"
          :key="index"
          :class="{
            'link-group-sm': $vuetify.breakpoint.sm,
            'link-group-xs': $vuetify.breakpoint.xs
          }"
        > -->
          <!-- <h5 class="link-group-title">{{ group.text }}</h5> -->
        <!-- </div> -->
      </v-col>

      <!-- <v-col
        cols="12"
        sm="12"
        md="6"
        class="content-image-container"
        style="justify-content: center;"
      >
        <v-row
          class="content mx-auto"
          :class="{
            'steps-container-md-up': $vuetify.breakpoint.mdAndUp,
            'steps-container-sm-down': $vuetify.breakpoint.smAndDown
          }"
        >
          <v-col
              cols="12"
              sm="12"
              md="6"
              class="content-image-container"
              style="justify-content: center;"
              v-for="(item, index) in spec.items"
              :key="index"
            >
            <img
              :class="{
                'content-image-lg-up': $vuetify.breakpoint.lgAndUp,
                'content-image-md': $vuetify.breakpoint.mdOnly,
                'content-image-sm-down': $vuetify.breakpoint.smAndDown
              }"
              :src="
                item.imageUrl
              "
            />
          </v-col>
        </v-row>
      </v-col> -->
    </v-row>

      <v-row
        style="width: 100%;"
        class="content mx-auto"
        :class="{
          'steps-container-md-up': $vuetify.breakpoint.mdAndUp,
          'steps-container-sm-down': $vuetify.breakpoint.smAndDown
        }"
      >
        <v-col align-self="center" cols="12" sm="12" md="4" class="steps-text">
          <a
            v-for="(button, buttonIndex) in spec.column1Buttons"
            :key="buttonIndex"
            class="footer-link"
            :href="$href(button)"
            @click="$onClick($event, button)"
            >{{ button.text }}</a
          >
        </v-col>

        <v-col align-self="center" cols="12" sm="12" md="4" class="steps-text">
          <a
            v-for="(button, buttonIndex) in spec.column2Buttons"
            :key="buttonIndex"
            class="footer-link"
            :href="$href(button)"
            @click="$onClick($event, button)"
            >{{ button.text }}</a
          >
        </v-col>

        <v-col align-self="center" cols="12" sm="12" md="4" class="steps-text">
          <a
            v-for="(button, buttonIndex) in spec.column3Buttons"
            :key="buttonIndex"
            class="footer-link"
            :href="$href(button)"
            @click="$onClick($event, button)"
            >{{ button.text }}</a
          >
        </v-col>
      </v-row>

      <v-row
        class="content"
      >
        <div style="margin: 10px 26px;">
          <a
            href="https://www.instagram.com/neowork_nz/"
            target="_blank"
            ><img :src="spec.instagramIconUrl" style="height: 24px" /></a
          >
          <a
            style="margin-left: 4px;"
            href="https://www.facebook.com/neoworknz"
            target="_blank"
            ><img :src="spec.facebookIconUrl" style="height: 24px" /></a
          >
        </div>
      </v-row>
    </div>

    <!--
    <div
      :class="{
        'top-footer-lg-up': $vuetify.breakpoint.lgAndUp,
        'top-footer-md': $vuetify.breakpoint.md
      }"
      justify-center
      row
      wrap
    >
      <div
        :class="{
          'top-content-container-lg-up': $vuetify.breakpoint.lgAndUp,
          'top-content-container-md': $vuetify.breakpoint.md,
          'top-content-container-sm': $vuetify.breakpoint.smAndDown,
          'top-content-container-xs': $vuetify.breakpoint.xs
        }"
      >
        <div
          :class="{
            'top-footer-left-content': $vuetify.breakpoint.mdAndUp,
            'top-footer-left-content-sm': $vuetify.breakpoint.smAndDown
          }"
        >
          <a v-if="logo"
            :href="$href(logo)"
            @click="$click(logo)"
          >
            <img
              :src="TODO"
              alt="Logo"
            />
          </a>
        </div>
        <div
          :class="{
            'download-badge-container-md-up': $vuetify.breakpoint.mdAndUp,
            'download-badge-container-sm': $vuetify.breakpoint.smAndDown,
            'download-badge-container-xs': $vuetify.breakpoint.xs
          }"
        >
          <a
            v-for="(link, index) in downloadLinks"
            :key="index"
            :href="$href(link)"
            class="download-link"
            @click="$onClick(link)"
          >
            <img
              class="download-badge"
              :class="{ 'download-badge-app-store': link.title == 'app-store' }"
              :src="link.image"
              :alt="`Download ${link.title}`"
            />
          </a>
        </div>
        <div
          class="top-footer-middle-content"
          :class="{
            'top-footer-middle-content-lg-up': $vuetify.breakpoint.lgAndUp,
            'top-footer-middle-content-md': $vuetify.breakpoint.md,
            'top-footer-middle-content-sm': $vuetify.breakpoint.sm,
            'top-footer-middle-content-xs': $vuetify.breakpoint.xs
          }"
        >
          <div
            v-for="(group, index) in navLinks"
            :key="index"
            :class="{
              'link-group-sm': $vuetify.breakpoint.sm,
              'link-group-xs': $vuetify.breakpoint.xs
            }"
          >
            <h5 class="link-group-title">{{ group.text }}</h5>
            <a
              v-for="(button, buttonIndex) in group.childItems"
              :key="buttonIndex"
              class="footer-link"
              :href="$href(button)"
              @click="$onClick($event, button)"
              >{{ button.text }}</a
            >
          </div>
        </div>

      </div>
    </div>
    -->

    <div class="bottom-footer py-6">
      <div class="content">
        <p class="copyright">
          {{ spec.copyrightText }}
        </p>
      </div>
    </div>
  </v-footer>
</template>

<script>
// import Settings from "../../settings";
export default {
  props: {
    spec: { type: Object, required: true }
  },
  data() {
    return {
      // logo: null,
      navLinks: null,
      downloadLinks: null
    };
  },
  methods: {
    $ready() {
      // this.logo = this.spec.logo;
      this.navLinks = this.spec.navLinks;
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 36px;
  background-color: #000000;
  // background-color: #241e1e;
  // background-color: #3e9fb7;
  // border-top: 2px solid #313131;
}

/* .top-footer {
  width: 100%;
  display: grid;
} */
.logo {
  // margin-left: -6px;
  // width: 200px;
  height: 36px;
  margin-bottom: 16px;
}

.top-footer-lg-up,
.top-footer-md,
.top-footer-xs {
  width: 100%;
  display: grid;
}

.top-footer-lg-up {
  height: 200px;
  grid-template-columns: 1fr 1025px 1fr;
}
.top-footer-md {
  height: 239px;
  grid-template-columns: 1fr 900px 1fr;
}
.top-content-container-lg-up,
.top-content-container-md {
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-column: 2/3;
  grid-row: 1/2;
  grid-column-gap: 20px;
}
.top-content-container-lg-up {
  grid-template-columns: 155px auto 1fr;
  padding: 58px 0px 25px 0px;
}
.top-content-container-md {
  grid-template-columns: 1fr auto 1fr;
  padding: 50px 0px 30px 0px;
}
.top-content-container-sm {
  display: grid;
  padding: 20px 0px;
  width: 90vw;
}
.top-content-container-xs {
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: 20px;
  width: 100vw;
}
.top-footer-left-content {
  grid-column: 1/2;
  height: 100%;
  display: grid;
  padding-right: 20px;
}
.top-footer-left-content-sm {
  grid-column: 1/2;
  grid-row: 1/2;
  padding-bottom: 20px;
}
.top-footer-middle-content {
  grid-column: 2/3;
  height: 100%;
  display: grid;
  max-width: 100%;
}
.top-footer-middle-content-lg-up {
  justify-self: center;
  grid-row: 1/2;
  grid-template-columns: repeat(5, minmax(auto, 170px));
}
.top-footer-middle-content-md {
  justify-self: center;
  grid-row: 1/2;
  grid-template-columns: repeat(5, minmax(auto, 139px));
  column-gap: 10px;
}
.top-footer-middle-content-sm {
  justify-self: center;
  grid-column: 1/2;
  grid-row: 2/3;
  padding-left: 5px;
  padding-right: 5px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  grid-template-columns: repeat(5, minmax(auto, 170px));
}
.top-footer-middle-content-xs {
  justify-self: center;
  grid-column: 1/2;
  grid-row: 2/3;
  height: 100%;
  width: 100%;
  padding: 0px 5px;
  flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-template-columns: repeat(5, minmax(auto, 170px));
}
.link-group-sm {
  max-width: 150px;
  padding-right: 8px;
}
.link-group-xs {
  max-width: 127px;
  min-width: 45%;
  padding-right: 5px;
  padding-bottom: 20px;
}
.link-group-xs:nth-child(even) {
  align-self: end;
}
.link-group-title {
  color: #d8d8d8;
  padding-bottom: 9px;
}
.footer-link {
  font-family: 'CeraGRThin';
  // color: #9f9f9f;
  color: #ffffff;
  text-decoration: none;
  font-size: 22px;
  display: block;
  margin-bottom: 6px;
  transition: 0.1s;
}
.footer-link:hover {
  // color: #aafb1e;
}
.top-footer-right-content-md-up {
  grid-column: 3/4;
  grid-row: 1/2;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.top-footer-right-content-sm {
  grid-column: 1/2;
  grid-row: 1/2;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.bottom-footer {
  // background: #181616;
  // background-color: #3e9fb7;
  // background-color: #000000;
  width: 100%;
  // padding-top: 36px;

  .content {
    justify-content: space-between;
    align-items: center;
    grid-column: 2/3;
    // grid-row: 1/2;
    display: flex;

    .copyright {
      font-family: 'CeraGRThin';
      margin-left: 12px;
      margin-bottom: 0px !important;
      font-size: 16px;
      // color: #9f9f9f;
      color: #ffffff;
    }
  }
}

.xl,
.lg {
  .bottom-footer {
    display: grid;
    grid-template-columns: 1fr 1025px 1fr;
  }
}
.md {
  .bottom-footer {
    display: grid;
    grid-template-columns: 1fr 900px 1fr;
  }
}
.sm {
  .bottom-footer {
    display: grid;
    grid-template-columns: 1fr 90vw 1fr;
  }
}
.xs {
  .bottom-footer {
    .content {
      padding: 0px 38px;
      flex-wrap: wrap-reverse;
      justify-content: center;
    }
  }
}
</style>
